<template>
  <div class="product-container">
    <!-- banner_cp -->
    <div class="banner_cp" style="background-color: #f4f6f8">
      <div class="margin width1480 hidden">
        <div class="l">
          <dl style="display: block">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_sxx.png"
            />
          </dl>
          <dl class="video-bg-dl">
            <video
              controls
              name="media"
              loop="loop"
              controlsList="nodownload"
              id="myvideo"
              class="sxx_video_style"
            >
              <source
                src="https://img.cdn.wxzhida.cn/shzd-web-site/mp4/%E7%94%B5%E5%9B%A7-%E7%A5%9E%E8%A1%8C%E4%BE%A0.mp4"
                type="video/mp4"
              />
            </video>
            <a href="javascript:void(0);" rel="popupre" class="popup bf sj">
              <div class="bg">
                <ol>
                  <i class="fa fa-play"></i>
                </ol>
              </div>
            </a>
          </dl>
        </div>
        <div class="r">
          <div class="bg a-fdB">
            <dd>神 行 侠</dd>
            <dl class="no-break-line">
              出行补电神器 | 多功能多场景 | 轻便套包好携带
            </dl>
            <ol class="f">
              <dt class="o">
                <span><i style="background-color: #000000"></i></span>
                <p>黑色</p>
              </dt>
            </ol>

            <div class="kaituozhe-flex-div">
              <a @click="$U.openProductFile('shenxingxia')">产品手册</a>
              <a class="movie-view-a" @click="viewVideoFun()">观看视频</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 实力派 -->
    <div id="s1">
      <div class="sxx_slp">
        <div class="margin width1480 hidden">
          <div class="tit">
            <dl>实力派</dl>
            <dd>先锋登场 多功能实力派</dd>
            <dl>4大功能一套搞定 轻松收纳时尚便携</dl>
          </div>
          <div class="img">
            <div class="c">
              <dl class="d1">
                <span class="l">多功能延长线</span
                ><span class="r"><i></i></span>
              </dl>
              <dl class="d2">
                <span class="l">套包</span><span class="r"><i></i></span>
              </dl>
              <dl class="d3">
                <span class="l">转换头</span><span class="r"><i></i></span>
              </dl>
              <dl class="d4">
                <span class="l">放电模块</span><span class="r"><i></i></span>
              </dl>
              <dl class="d5">
                <span class="r"><i></i></span><span class="l">充电模块</span>
              </dl>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_slp.png"
              />
            </div>
          </div>
          <ul>
            <li class="l">
              <div
                class="l"
                style="
                  background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_slp1.jpg);
                "
              ></div>
              <div class="r">
                <div>
                  <dd>出行补电</dd>
                  <dl>charging</dl>
                </div>
              </div>
            </li>
            <li class="l">
              <div
                class="l"
                style="
                  background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_slp2.jpg);
                "
              ></div>
              <div class="r">
                <div>
                  <dd>大功率放电</dd>
                  <dl>discharge</dl>
                </div>
              </div>
            </li>
            <li class="l">
              <div
                class="l"
                style="
                  background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_slp3.jpg);
                "
              ></div>
              <div class="r">
                <div>
                  <dd>车辆救援</dd>
                  <dl>Vehicle rescue</dl>
                </div>
              </div>
            </li>
            <li class="r">
              <div
                class="l"
                style="
                  background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_slp4.jpg);
                "
              ></div>
              <div class="r">
                <div>
                  <dd>延长充电距离</dd>
                  <dl>Longer distance</dl>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 性之王 -->
    <div id="s2">
      <div
        class="sxx_xzw"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_xzw.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="tit">
            <!-- <ol>性价之王</ol> -->
            <dd>出行新势力 用电不焦虑</dd>
            <dl>免接地即插即用 应急充电就靠它</dl>
          </div>
        </div>
      </div>
    </div>
    <!-- cp_aqfh -->
    <div id="s3">
      <div class="margin width1480 hidden">
        <ul class="cp_aqfh">
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh1.png"
                />
              </ol>
              <dd>过压/欠压保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh2.png"
                />
              </ol>
              <dd>过流保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh3.png"
                />
              </ol>
              <dd>雷电保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh4.png"
                />
              </ol>
              <dd>接地保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh5.png"
                />
              </ol>
              <dd>防漏电保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh6.png"
                />
              </ol>
              <dd>短路保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh12.png"
                />
              </ol>
              <dd>密码锁防盗</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/cp_aqfh8.png"
                />
              </ol>
              <dd>IP55</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh9.png"
                />
              </ol>
              <dd>过温/低温保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh11.png"
                />
              </ol>
              <dd>三眼插座</dd>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 专业认证 使用更安心 -->
    <div id="s4">
      <div
        class="sxx_zyrz"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_zyrz.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="tit">
            <dd>IP55防护等级 使用更安心</dd>
            <dl>防水防尘 轻松应对户外环境</dl>
          </div>
        </div>
      </div>
    </div>
    <!-- sxx_zyrz_tit -->
    <div id="s5">
      <div class="sxx_zyrz_tit">
        <dd>匠心之作 打磨品质细节</dd>
        <dl>挚达神行侠 电车出行套包</dl>
      </div>
    </div>
    <!-- 360°环绕LED灯 -->
    <div id="s6">
      <div
        class="sxx_hrd"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_hrd_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_hrd.png"
            class="l"
          />
          <div class="r">
            <dd>360°环绕LED灯</dd>
            <dl>一眼掌握工作状态</dl>
          </div>
        </div>
      </div>
    </div>
    <!-- 集成密码锁 -->
    <div id="s7">
      <div
        class="sxx_jcmms"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_jcmms_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="l">
            <dd>集成密码锁</dd>
            <dl>防止被盗和误拔</dl>
          </div>
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_jcmms.png"
            class="r"
          />
        </div>
      </div>
    </div>
    <!-- 体工学设计 -->
    <div id="s8">
      <!-- <div class="sxx_tgxsj" style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_tgxsj_bg.jpg);">
    <div class="margin width1480 hidden">
        <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_tgxsj.png" class="l" />
        <div class="r">
            <dd>人体工学设计</dd>
            <dl>方便把握和插拔</dl>
        </div>
    </div>
    </div> -->
    </div>
    <!-- 充放兼备 唤醒前进动力 -->
    <div id="s9">
      <div
        class="cp_f_bg sxx_f_bg cp_f_bg_full"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/sxx_f_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <dd>充放兼备 唤醒前进动力</dd>
            <dl>车辆救援 拯救户外“趴窝”</dl>
            <!-- <ol><a href="">产品手册<i class="fa fa-angle-right"></i></a></ol> -->
          </div>
        </div>
      </div>
    </div>

    <div class="template-full-mask-div" v-if="state.showFullVideoFlag">
      <div class="center-view-view-div">
        <video
          controls
          name="media"
          loop="loop"
          controlsList="nodownload"
          id="myvideo"
          class="ktz_video_style"
        >
          <source
            src="https://img.cdn.wxzhida.cn/shzd-web-site/mp4/v1/%E7%94%B5%E5%9B%A7-%E6%8C%9A%E8%BE%BE%E7%A5%9E%E8%A1%8C%E4%BE%A0%E5%87%BA%E8%A1%8C%E5%85%85%E7%94%B5%E7%BB%84%E5%90%88%E5%A5%97%E5%8C%85.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <div
        class="fixed-close-icon-div"
        @click="state.showFullVideoFlag = false"
      >
        <i class="fa fa-close"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
export default {
  name: "shouwangzhe",
  components: {},
  setup() {
    const state = reactive({
      showFullVideoFlag: false,
    });

    const viewVideoFun = () => {
      state.showFullVideoFlag = true;
    };

    const { proxy } = getCurrentInstance();
    onMounted(() => {
      document.title = "神行侠系列";
      // 滚动到页面最上方
      window.scrollTo(0, 0);
      $(".popupbox_i").click(function () {
        $(this).hide();
        $("#myvideo")[0].pause();
      });

      $(".bf").click(function () {
        $(this).hide();
        $("#myvideo")[0].play();
      });
      $(".banner_cp .width1480 .r ol.f dt").click(function () {
        var liindex = $(".banner_cp .width1480 .r ol.f dt").index(this);
        $(this).addClass("o").siblings().removeClass("o");
        $(".banner_cp .width1480 .l dl")
          .eq(liindex)
          .fadeIn(150)
          .siblings("dl")
          .hide();
      });
      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (var i = 1; i < 20; i++) {
          if ($("#s" + i) && $("#s" + i).offset()) {
            //加循环
            if ($("#s" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#s" + i).addClass("on"); //给当前导航加c类
            } else {
              //$("#p"+i).removeClass('on');
            }
          }
        }
      });
    });
    return {
      state,
      viewVideoFun,
    };
  },
};
</script>

<style lang="less" scoped>
.ktz_video_style {
  width: 100%;
}

.video-bg-dl {
  padding-top: 150px;
}

.video-bg-dl a {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  color: #ffffff;
  display: flex;
  text-align: center;
}

.video-bg-dl ol {
  font-size: 1.5rem;
  position: relative;
  display: flex;
  width: 666px;
  justify-content: center;
  margin-top: 330px;
}

.video-bg-dl ol i {
  transition: all 0.5s ease;
  border: 2px solid #ffffff;
  position: absolute;
  width: 2.875rem;
  height: 3.375rem;
  line-height: 3.375rem;
  border-radius: 50%;
  font-weight: 300;
  padding-left: 0.5rem;
}

.kaituozhe-flex-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kaituozhe-flex-div a {
  margin: 2.5vh 20px 0px !important;
}

.kaituozhe-flex-div .movie-view-a {
  color: #364166 !important;
  background-color: #ffffff !important;
  border: 2px solid #364166 !important;
  font-weight: 600;
}

.template-full-mask-div {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000d4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10086;
}

.center-view-view-div {
  width: 80%;
  height: calc(80% - 88px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.kaituozhe_video_style {
  z-index: 10089;
  width: 100%;
  height: 100%;
}

.fixed-close-icon-div {
  position: fixed;
  right: 32px;
  top: 32px;
  color: #f2f2ff;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 400;
}
</style>
